import { Flex } from "@chakra-ui/react"
import Link from "next/link"
import CookieConsent from "react-cookie-consent"

const Consent = () => {
    return <>
        <Flex justify="center" align="center">
            <CookieConsent
                location="bottom"
                buttonText="Confirmar"
                cookieName="cookie-consent"
                style={{ background: "rgba(255, 255, 255, 1)", padding: "10px", display: "flex", alignItems: "center", fontSize: "14px", color: "#3a3a3a", width: "80%", marginLeft: "auto", borderRadius: "10px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", marginBottom: "10px" }}
                buttonStyle={{ color: "#FFFFFF", fontWeight: "800", fontSize: "13px", borderRadius: "5px", background: "#789700" }}
            >
                Utilizamos cookies e tecnologias para melhorar a sua experiência em nosso site. <br />Ao acessar e navegar, você aceita e concorda com as condições.
                <Link href={'/aviso-de-privacidade'}> <b>Aviso de Privacidade</b>.</Link>
            </CookieConsent>
        </Flex>
    </>
}

export default Consent