'use client'

import { Box, Skeleton } from "@chakra-ui/react";

import Header from './header/page'
//import Video from './video/video'
//import Banner from './banner/page'
//import About from './about/about'
//import Numbers from './numbers/numbers'
//import Culture from './culture/culture'
//import Partiners from './partiners/partiners'
//import BlogPosts from '@/components/blog/BlogPosts'

//import BannerComponent from '@/components/footer/banner'
//import FooterComponent from '@/components/footer/footer'

import dynamic from "next/dynamic";
import { Suspense } from "react";
import Loading from "./loading";
import Consent from "@/components/Consent";

//const Header = dynamic(() => import('./header/page'))
const Video = dynamic(() => import('./video/video'))
const Banner = dynamic(() => import('./banner/page'))
const About = dynamic(() => import('./about/about'))
const Numbers = dynamic(() => import('./numbers/numbers'))
const Culture = dynamic(() => import('./culture/culture'))
const Partiners = dynamic(() => import('./partiners/partiners'))

const BlogPosts = dynamic(() => import('@/components/blog/BlogPosts'))
const BannerComponent = dynamic(() => import('@/components/footer/banner'))
const FooterComponent = dynamic(() => import('@/components/footer/footer'))

const HomePageFront = (props: any) => {
    const beforeWrapper = {
        content: "''",
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '223px',
        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.75) 0%, rgba(252, 252, 252, 0) 99%, rgba(255, 255, 255, 0) 100%)',
        transition: '.3s',
        zIndex: 2
    }
    const {
        header,
        video,
        ConteudoSegmentos,
        aboutData,
        dataNumbers,
        cultureData,
        dataPartiners,
        news,
        attributes,
        config,
        blogPost,
        menu
    } = props


    return (
        <Box>
            <Header header={header} />
            <Box pos={'relative'} _before={beforeWrapper} overflow={'hidden'}>
                <Video data={video} />
                <Banner ConteudoSegmentos={ConteudoSegmentos} config={config} />
                <About aboutData={aboutData} />
                <Numbers dataNumbers={dataNumbers} />
                <Culture cultureData={cultureData} />
                <Partiners dataPartiners={dataPartiners} />
                <BlogPosts news={news} data={blogPost} config={config} />
                <BannerComponent data={attributes?.NuvemPalavras} />
                <FooterComponent data={attributes} config={config} menu={menu} />
            </Box>
            <Consent />
        </Box>
    );
};
export default HomePageFront;