import HeaderInitComponent from '@/components/header/header-init'
import HeaderMobile from '@/components/header/header-mobile'
import HeaderScrollComponent from '@/components/header/header-scroll'

export default async function HeaderSlot(props:any) {
  const { header } = props
  if(!header) return <></>
  
  return (
    <>
    <HeaderInitComponent data={header} />
    <HeaderScrollComponent data={header} />
    <HeaderMobile data={header} />
    </>
  )
}
